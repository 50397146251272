<template>
    <div>
        <section id="page-title" class="page-title-parallax page-title-dark" style="background-image: url('/assets/images/about/parallax.jpg'); padding: 120px 0;" data-bottom-top="background-position:0px 300px;" data-top-bottom="background-position:0px -300px;">

			<div class="container clearfix">
				<h1>Job Openings</h1>
				<span>Join our Fabulous Team of Intelligent Individuals</span>
				<ol class="breadcrumb">
					<li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
					<li class="breadcrumb-item active" aria-current="page">Jobs</li>
				</ol>
			</div>

		</section><!-- #page-title end -->
        <!-- Content
		============================================= -->
		<section id="content">
			<div class="content-wrap">
				<div class="container clearfix">
                    
					<div class="row col-mb-50">
                        <!-- sidebar -->
                        <div class="col-md-4">
                            <Sidebar/>
                        </div>
                        <!-- JD  -->
						<div class="col-md-8">
							<div>
                                <h3 style="width: 100%;padding: 0 0 0.75rem;border-bottom: 2px solid #1ABC9C;">General Accountant
                                <router-link to="/careers/general-accountant/form" class="button button-3d m-0 button-primary" style="float:right">Apply Now</router-link></h3>
                                <h4>Who we Are</h4>
                                <p>eFlexervices is a BPO company with a legacy spanning 22 years, we've honed our craft in providing exceptional quality and building unshakable trust. At eFlex, we're not just a BPO company – we're your partners in success. Our approach is all about finding the perfect match between talent and the organizations we support. We're not just investing in our work; we're investing in people, optimizing performance, and maximizing efficiency. We are all about exceptional quality and unwavering trust.</p>
                                <h4>What We Are Looking For</h4>
                                <p>Are you ready to take your expertise to the next level? At eFlexervices, we're not just hiring for a <strong>General Accountant</strong> - we're empowering your talent to deliver quality and performance. Join us in shaping the success and making an impact that truly matters.</p>
                                <p>The Sales Reporting Analyst will be reporting to the Finance Manager, managing financial relationships with bus operators. Responsibilities include becoming a subject matter expert on assigned operators, reconciling sales data, preparing reports and invoices, handling payment requests, and addressing operator queries. The role also involves general accounting tasks such as month-end closing, journal entries, and balance sheet reconciliations.</p>

                                <div class="accordion accordion-bg">

                                    <div class="accordion-header">
                                        <div class="accordion-icon">
                                            <i class="accordion-closed icon-ok-circle"></i>
                                            <i class="accordion-open icon-remove-circle"></i>
                                        </div>
                                        <div class="accordion-title">
                                            What you'll be doing
                                        </div>
                                    </div>
                                    <div class="accordion-content">
                                        <ul class="iconlist iconlist-color mb-0">
                                            <li><i class="icon-ok"></i>Reports to the Finance manager, responsible for managing the financial relationship with an assigned portfolio of bus operators and performing general accounting duties.</li>
                                            <li><i class="icon-ok"></i>Become “subject matter expert” in assigned operators, ensuring all data held on operators is accurate and systems are populated correctly</li>
                                            <li><i class="icon-ok"></i>Work closely with GlobalOps and other teams to understand and document flows of data, funds and specific sales reporting processes for assigned operators including updating the information when necessary</li>
                                            <li><i class="icon-ok"></i>Reconciliation of sales data between operator and reports, and to accounting system (Netsuite)</li>
                                            <li><i class="icon-ok"></i>Preparation and communication of sales reports and commission invoices</li>
                                            <li><i class="icon-ok"></i>Preparation of payment requests for approval</li>
                                            <li><i class="icon-ok"></i>Recording and/or review of operator payments in Netsuite</li>
                                            <li><i class="icon-ok"></i>Response to operator queries regarding sales reporting and payments</li>
                                            <li><i class="icon-ok"></i>Monthly reconciliation of all payables and receivables for assigned operators</li>
                                            <li><i class="icon-ok"></i>Monitor operators inboxes and respond to operator queries professionally and timely</li>
                                            <li><i class="icon-ok"></i>Ensure new operators are onboarded correctly, including validating identification and banking information</li>
                                        </ul>
                                    </div>

                                    <div class="accordion-header">
                                        <div class="accordion-icon">
                                            <i class="accordion-closed icon-ok-circle"></i>
                                            <i class="accordion-open icon-remove-circle"></i>
                                        </div>
                                        <div class="accordion-title">
                                            General Accounting
                                        </div>
                                    </div>
                                    <div class="accordion-content">
                                        <ul class="iconlist iconlist-color mb-0">
                                            <li><i class="icon-ok"></i>Perform month-end closing tasks</li>
                                            <li><i class="icon-ok"></i>Record journal entries</li>
                                            <li><i class="icon-ok"></i>Analyze and reconcile accounts</li>
                                            <li><i class="icon-ok"></i>Perform balance sheet reconciliations</li>
                                            <li><i class="icon-ok"></i>Create account movement tracking schedules</li>
                                            <li><i class="icon-ok"></i>Assist with other related accounting tasks</li>
                                        </ul>
                                    </div>

                                    <div class="accordion-header">
                                        <div class="accordion-icon">
                                            <i class="accordion-closed icon-ok-circle"></i>
                                            <i class="accordion-open icon-remove-circle"></i>
                                        </div>
                                        <div class="accordion-title">
                                            Qualifications and Requirements
                                        </div>
                                    </div>
                                    <div class="accordion-content">
                                        <ul class="iconlist iconlist-color mb-0">
                                            <li><i class="icon-ok"></i>5 years + of experience as a financial analyst</li>
                                            <li><i class="icon-ok"></i>Familiar with revenue related journal entries, multi-currency and multi-company environment; good overall accounting knowledge</li>
                                            <li><i class="icon-ok"></i>Must be knowledgeable with the US GAAP</li>
                                            <li><i class="icon-ok"></i>Excel expert, NetSuite or other ERP an asset</li>
                                            <li><i class="icon-ok"></i>Excellent verbal and written communication skills</li>
                                            <li><i class="icon-ok"></i>BAC in accounting</li>
                                            <li><i class="icon-ok"></i>Team-player, self-starter, able to handle multiple projects and deadlines simultaneously</li>
                                        </ul>
                                    </div>
                                    
                                    <div class="accordion-header">
                                        <div class="accordion-icon">
                                            <i class="accordion-closed icon-ok-circle"></i>
                                            <i class="accordion-open icon-remove-circle"></i>
                                        </div>
                                        <div class="accordion-title">
                                            What you'll enjoy at eFlex
                                        </div>
                                    </div>
                                    <div class="accordion-content">
                                        <p>At eFlex, we're not just a workplace - we're a community of playmakers, committed to fostering growth, learning, and personal connections. Here's what's in store for you:</p>
                                        <ul class="iconlist iconlist-color mb-0">
                                            <li><i class="icon-plus-sign"></i><strong>Living Our Values:</strong> We don't just talk the talk; we walk the walk. From learning and personal growth to caring deeply about our team and clients, our values are the compass guiding our vibrant workplace.</li>
                                            <li><i class="icon-plus-sign"></i><strong>Competitive Compensation:</strong> Your hard work deserves recognition. Enjoy a competitive salary and benefits package, including comprehensive HMO coverage and optical reimbursements.</li>
                                            <li><i class="icon-plus-sign"></i><strong>Flexible Work Options:</strong> Work your way! If you're outside Baguio City, embrace a permanent work-from-home setup. For those within Baguio, our hybrid work model offers the best of both worlds.</li>
                                            <li><i class="icon-plus-sign"></i><strong>Wellness Matters:</strong> Recharge and refresh! Our flexible vacation and sick leaves empower you to prioritize your well-being, ensuring a healthy work-life balance.</li>
                                            <li><i class="icon-plus-sign"></i><strong>Pathways to Success:</strong> Your journey with us is brimming with growth opportunities. We're dedicated to nurturing your career and supporting your rise through the ranks.</li>
                                        </ul>
                                    </div>

                                    <p>Ready to embark on an enriching journey? Join the eFlex family and experience a workplace that values your individuality and success. Let's thrive together!</p>
                                    <router-link to="/careers/general-accountant/form" class="button button-3d m-0 button-primary" style="float:left">Apply Now</router-link>
                                </div>
                                <div class="divider divider-sm"></div>
                            </div>
						</div>
                        
                    </div>

				</div>
			</div>
		</section><!-- #content end -->

    </div>
</template>

<script>
import Sidebar from '@/components/CareerNav.vue'
export default {
    data() {
        return {
            
        }
    },
    components: {
        Sidebar
    },
    mounted() {
		window.scrollTo(0, 0)
	}
}
</script>